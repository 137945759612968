import { Flex, Image } from '@chakra-ui/core';
import React from 'react';

import ericRiesSignature from 'assets/images/landing/banners/eric-ries-signature.svg';
import ericPng from 'assets/images/landing/banners/eric_ries.png';
import ericWebp from 'assets/images/landing/banners/eric_ries.webp';

import LoadOnScroll from '../LoadOnScroll';
import CascadingText from '../shared/CascadingText';
import { Container, LoadWrapper, LoadWrapperFlex } from '../shared/styled';
import { BannerQuote, BannerQuoteAuthor } from './styled';

interface Props {
  isWebpSupported: boolean;
}

const PhotoBanner: React.FC<Props> = ({ isWebpSupported }) => {
  return (
    <LoadOnScroll>
      <LoadWrapper py="9.4rem" bg="gray.a800">
        <Container>
          <Flex
            direction={['column', 'column', 'row']}
            justify={['flex-start', 'flex-start', 'center']}
          >
            <LoadOnScroll>
              <LoadWrapperFlex
                w={['100%', '100%', 208]}
                maxW={208}
                justify={['flex-start', 'flex-start', 'flex-end']}
                align="flex-start"
                mr="102px"
                style={{ transitionDelay: '0.5s' }}
              >
                <Image
                  src={isWebpSupported ? ericWebp : ericPng}
                  alt="Eric Ries"
                  w="100%"
                  pb="40px"
                />
              </LoadWrapperFlex>
            </LoadOnScroll>

            <Flex
              w={['100%', '100%', '544px']}
              maxW="544px"
              direction="column"
              justify="space-around"
            >
              <BannerQuote
                fontSize={['xxxl', 'xxxl', 'freakingHuge']}
                lineHeight={['3rem', '3rem', '5.28rem']}
                pb={60}
              >
                <CascadingText
                  text="“The only way to win is to learn faster than anyone else.”"
                  initialDelay={0.5}
                />
              </BannerQuote>
              <LoadOnScroll>
                <LoadWrapper
                  my={['1rem', '1rem', 0]}
                  style={{ transitionDelay: '1.3s' }}
                >
                  <Image
                    src={ericRiesSignature}
                    alt="signature of Eric Ries"
                    w={236}
                  />
                  <BannerQuoteAuthor pt="10px">
                    The Lean Startup
                  </BannerQuoteAuthor>
                </LoadWrapper>
              </LoadOnScroll>
            </Flex>
          </Flex>
        </Container>
      </LoadWrapper>
    </LoadOnScroll>
  );
};

export default PhotoBanner;
