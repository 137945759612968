import { action, observable } from 'mobx';

import { api } from 'services/api';
import { Specification } from 'services/model';

import { ApiStore } from './apiStore';
import { RootStore } from './rootStore';

export class SpecificationStore {
  constructor(rootStore: RootStore, apiStore: ApiStore) {
    this.rootStore = rootStore;
    this.apiStore = apiStore;
  }
  private rootStore: RootStore;
  private apiStore: ApiStore;
  @observable isLoading = false;
  @observable isError = false;
  @observable specificationId = '';

  @action
  async getSpecification(id: string): Promise<void> {
    this.specificationId = id;
    this.setError(false);
    this.setLoading(true);
    try {
      let response = await api.getSpecification(id);
      // TODO: Send appropriate data from BE instead of this
      const {
        goal,
        problems,
        vision,
        timeline,
        budgets,
        risks,
        ...specification
      } = response.data.specification;
      this.setSpecification({
        goalsAndProjectDescription: { goal, problems, vision },
        timelineBudgetsRisks: { timeline, budgets, risks },
        ...specification,
      });
    } catch {
      this.setError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @action
  async createSpecification(): Promise<void> {
    this.setError(false);
    this.setLoading(true);
    try {
      const response = await api.createSpecification();
      this.specificationId = response?.data.id;
    } catch {
      this.setError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @action setSpecification = (newSpec: Specification) => {
    this.apiStore.values = { ...this.apiStore.values, ...newSpec };
  };

  @action setLoading = (status: boolean) => {
    this.isLoading = status;
  };

  @action setError = (status: boolean) => {
    this.isError = status;
  };
}
