import { Select, SelectProps } from '@chakra-ui/core';
import React from 'react';

export type OptionProps = {
  label: string;
  value: string | number;
};

type Props = Omit<SelectProps, 'css'> & {
  label: string;
  name: string;
  options: OptionProps[];
  setFieldValue: (name: string, value: string) => void;
  error?: string;
  touched?: boolean;
};

export const ModalDropdown: React.FC<Props> = ({
  name,
  label,
  placeholder,
  value,
  setFieldValue,
  options,
  error,
  touched,
  ...rest
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFieldValue(name, e.target.value);
  };

  let color = 'black';

  if (value === '') {
    color = 'gray.a400';
  }

  if (error && touched) {
    color = 'red.500';
  }

  return (
    <Select
      h="4rem"
      aria-label={label}
      name={name}
      placeholder={placeholder}
      errorBorderColor="red.500"
      isInvalid={error ? touched : false}
      onChange={handleChange}
      color={color}
      {...rest}
    >
      {options.map((option) => (
        <option value={option.value} key={option.label}>
          {option.label}
        </option>
      ))}
    </Select>
  );
};
