import { AxiosResponse } from 'axios';

import httpClient from 'utils/httpClient';

import { CreateTypes, Endpoints } from './model';

export const api = {
  get(specificationId: string, endpoint: Endpoints): Promise<AxiosResponse> {
    return httpClient.get(
      `/${Endpoints.SPECIFICATIONS}/${specificationId}/${endpoint}`,
    );
  },
  add(
    specificationId: string,
    endpoint: Endpoints,
    data: CreateTypes,
  ): Promise<AxiosResponse> {
    return httpClient.post(
      `/${Endpoints.SPECIFICATIONS}/${specificationId}/${endpoint}`,
      data,
    );
  },
  edit(
    specificationId: string,
    id: string | null,
    endpoint: Endpoints,
    data: CreateTypes,
  ): Promise<AxiosResponse> {
    return httpClient.put(
      `/${Endpoints.SPECIFICATIONS}/${specificationId}/${endpoint}${
        id ? '/' + id : ''
      }`,
      data,
    );
  },
  delete(
    specificationId: string,
    endpoint: Endpoints,
    id: string,
  ): Promise<AxiosResponse> {
    return httpClient.delete(
      `/${Endpoints.SPECIFICATIONS}/${specificationId}/${endpoint}/${id}`,
    );
  },
  addFile(
    specificationId: string,
    endpoint: Endpoints,
    formData: FormData,
  ): Promise<AxiosResponse> {
    return httpClient.post(
      `/${Endpoints.SPECIFICATIONS}/${specificationId}/${endpoint}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  getSpecification(id: string): Promise<AxiosResponse> {
    return httpClient.get(`/${Endpoints.SPECIFICATIONS}/${id}`);
  },
  createSpecification(): Promise<AxiosResponse> {
    return httpClient.post(`/${Endpoints.SPECIFICATIONS}`);
  },
};
