import { Box, Flex, Text } from '@chakra-ui/core';
import React from 'react';

import documentStructureImage from 'assets/images/landing/document-structure/document-structure.svg';
import { LandingPageButton } from 'components/dataDisplay';

import LoadOnScroll from '../LoadOnScroll';
import { Container, LoadWrapper } from '../shared/styled';
import { DocumentStructureImage, DocumentStructureTitle } from './styled';

interface Props {
  generateSpecification: () => void;
}

const DocumentStructure: React.FC<Props> = ({ generateSpecification }) => (
  <LoadOnScroll>
    <LoadWrapper backgroundImage="linear-gradient(to bottom, #ffffff, #f9f9f9)">
      <Box pb={['160px', '160px', '316px']} pos="relative">
        <Container>
          <Flex
            direction={[
              'column-reverse',
              'column-reverse',
              'column-reverse',
              'row',
            ]}
            align="center"
          >
            <Box w={['100%', '100%', '50%']} pt="8rem">
              <Box
                maxW="42rem"
                pr="1rem"
                textAlign={['center', 'center', 'center', 'left']}
              >
                <DocumentStructureTitle
                  fontSize={['xxl', 'xxl2', 'xxxl', 'xxxl2']}
                >
                  SRS
                  <br /> document structure
                </DocumentStructureTitle>
                <Text
                  fontSize={['md', 'md', 'md', 'md2']}
                  color="customBlack.100"
                  opacity={0.6}
                  lineHeight="32px"
                  pt="2rem"
                  pb="3.5rem"
                >
                  It is good to have a frame for your content so that you know
                  what to think of and what information to prepare and include
                  in the specification. Therefore, below are presented basic
                  contents of a specification template which we think should be
                  included in a simple yet comprehensive requirements document.
                </Text>
                <LandingPageButton
                  id="bottom-try-button"
                  mb={['4rem', '4rem', '4rem', 0]}
                  onClick={generateSpecification}
                  light
                >
                  Try for free
                </LandingPageButton>
              </Box>
            </Box>
            <Box
              w={['100%', '100%', '100%', '50%']}
              pl={[0, 0, 0, '1rem']}
              pt={[100, 100, 100, 0]}
            >
              <DocumentStructureImage
                src={documentStructureImage}
                alt="cartoon presenting a woman working on structuring the project"
              />
            </Box>
          </Flex>
        </Container>
      </Box>
    </LoadWrapper>
  </LoadOnScroll>
);

export default DocumentStructure;
