import React from 'react';

import { theme } from 'styles/theme';

import { PercentageBar } from './styled';

interface Props {
  length: number;
  dark?: boolean;
}

const Bar: React.FC<Props> = ({ length, dark }) => {
  return (
    <PercentageBar
      w={`${length * (100 / 42)}%`}
      opacity={dark ? 1 : 0.3}
      backgroundColor={dark ? theme.colors.green['300'] : ''}
    ></PercentageBar>
  );
};

export default Bar;
