import { Box, Image } from '@chakra-ui/core';
import React from 'react';
import { Controller, Scene } from 'react-scrollmagic';

import circleFull from 'assets/images/landing/intro/circle-full.svg';
import circleOutline from 'assets/images/landing/intro/circle-outline.svg';
import dottedSquare from 'assets/images/landing/intro/dotted-square.svg';
import introWindowsPng from 'assets/images/landing/intro/intro-windows.png';
import introWindowsWebp from 'assets/images/landing/intro/intro-windows.webp';
import largeOrbit from 'assets/images/landing/intro/large-orbit.svg';
import orbit from 'assets/images/landing/intro/orbit.svg';
import * as C from 'components/dataDisplay';

import LazyImage from '../BusinessPlan/LazyImage';
import LoadOnScroll from '../LoadOnScroll';
import { StandardLandingProps } from '../model';
import { Container, LoadWrapper } from '../shared/styled';
import * as S from './styled';

interface IntroProps extends StandardLandingProps {
  generateSpecification: () => void;
}

const Intro: React.FC<IntroProps> = ({
  generateSpecification,
  isWebpSupported,
}) => {
  return (
    <LoadOnScroll>
      <LoadWrapper
        pos="relative"
        backgroundColor="green.500"
        mb={['24vw', '24vw', '24vw', '27rem']}
        transition="height 1s"
        minH="50vh"
        style={{ transitionDelay: '0.3s' }}
      >
        <LazyImage
          src={circleFull}
          alt=""
          right="0"
          top="35%"
          transitionDelay="1.3s"
        />
        <LazyImage
          src={orbit}
          alt=""
          left="0"
          top="16%"
          w="10%"
          transitionDelay="1.2s"
        />
        <LazyImage
          src={largeOrbit}
          alt=""
          right="0"
          top="-0"
          w="60%"
          transitionDelay="1.2s"
        />
        <Container justifyContent="center" textAlign="center">
          <S.TitleWrapper>
            <LoadOnScroll>
              <LoadWrapper style={{ transitionDelay: '0.6s' }}>
                <S.Title fontSize={['2rem', '2.8rem', '3.5rem']} as="h1">
                  Write your Software Requirements Specification for a startup
                  MVP
                </S.Title>
              </LoadWrapper>
            </LoadOnScroll>
            <LoadOnScroll>
              <LoadWrapper style={{ transitionDelay: '0.7s' }}>
                <S.SubTitle>
                  SRS generator for everyone, from beginners to experts
                </S.SubTitle>
              </LoadWrapper>
            </LoadOnScroll>
            <LoadOnScroll>
              <LoadWrapper style={{ transitionDelay: '0.8s' }}>
                <C.LandingPageButton
                  id="intro-try-button"
                  onClick={generateSpecification}
                >
                  Try for free
                </C.LandingPageButton>
              </LoadWrapper>
            </LoadOnScroll>
          </S.TitleWrapper>
          <Box
            transform={[
              'translate(4%, 24vw)',
              'translate(4%, 24vw)',
              'translate(4%, 24vw)',
              'translate(4%, 24vw)',
              'translate(4%, 27rem)',
            ]}
            pos="relative"
            fontSize="20px"
          >
            <LazyImage
              src={dottedSquare}
              alt=""
              w="9%"
              left="4%"
              top="12%"
              transitionDelay="1.4s"
            />
            <LazyImage
              src={dottedSquare}
              alt=""
              w="9%"
              right="13%"
              bottom="48%"
              transitionDelay="1.5s"
            />
            <LazyImage
              src={circleOutline}
              alt=""
              right="17%"
              top="25%"
              transitionDelay="1.3s"
            />
            <LoadOnScroll>
              <LoadWrapper
                mt={['-28vw', '-28vw', '-28vw', '-28vw', '-34rem']}
                style={{ transitionDelay: '1s' }}
              >
                <Image
                  src={isWebpSupported ? introWindowsWebp : introWindowsPng}
                  alt="sample images presenting how does the SRS application look like"
                  w="100%"
                  pos="relative"
                  zIndex={2}
                />
              </LoadWrapper>
            </LoadOnScroll>
          </Box>
        </Container>
        <Controller>
          <Scene
            classToggle={['.scroll-magic-header', 'white']}
            triggerHook={0}
          >
            <Box transform="translate(0, -6.5rem)" />
          </Scene>
        </Controller>
      </LoadWrapper>
    </LoadOnScroll>
  );
};

export default Intro;
