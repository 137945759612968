import { Box, Flex } from '@chakra-ui/core';
import React from 'react';

import dottedSquare from 'assets/images/landing/business-plan/dotted-square.svg';
import greenBlob from 'assets/images/landing/business-plan/green-blob.svg';
import imageBot from 'assets/images/landing/business-plan/image-bot.png';
import imageBotWebp from 'assets/images/landing/business-plan/image-bot.webp';
import imageTop from 'assets/images/landing/business-plan/image-top.png';
import imageTopWebp from 'assets/images/landing/business-plan/image-top.webp';
import photoBotR from 'assets/images/landing/business-plan/photo-bot-r.png';
import photoMidL from 'assets/images/landing/business-plan/photo-m-l.png';
import photoTopL from 'assets/images/landing/business-plan/photo-top-l.png';
import photoTopR from 'assets/images/landing/business-plan/photo-top-r.png';

import LoadOnScroll from '../LoadOnScroll';
import { StandardLandingProps } from '../model';
import {
  BasicText,
  BasicTitle,
  Container,
  LoadWrapper,
} from '../shared/styled';
import LazyImage from './LazyImage';

const BusinessPlan: React.FC<StandardLandingProps> = ({ isWebpSupported }) => (
  <Box pt={['10rem', '14rem', '22rem']}>
    <Container>
      <Flex
        direction={['column', 'column', 'column', 'row']}
        justify="space-between"
        align="center"
      >
        <Box
          pt={['108%', '108%', '108%', '54%']}
          w={['100%', '100%', '100%', '50%']}
          mr={[0, 0, 0, '3.5rem']}
          mb={[0, 0, 0, 142]}
          pos="relative"
        >
          <LazyImage
            src={dottedSquare}
            alt=""
            top="10%"
            left="24.4%"
            w="18.3%"
            transitionDelay="0.1s"
          />
          <LazyImage
            src={isWebpSupported ? imageTopWebp : imageTop}
            alt="opened notebook with a meeting agenda written in it"
            top={0}
            left="49%"
            w="51%"
          />
          <LazyImage
            src={greenBlob}
            alt=""
            left="52.5%"
            top="45.5%"
            w="27.4%"
          />
          <LazyImage
            src={isWebpSupported ? imageBotWebp : imageBot}
            alt="development team during a daily standup meeting"
            top="31.4%"
            left={0}
            w="60%"
            transitionDelay="0.3s"
          />
          <LazyImage
            src={photoBotR}
            alt="team member avatar image"
            top="75.6%"
            left="73.6%"
            w="17.2%"
            transitionDelay="0.7s"
          />
          <LazyImage
            src={photoTopL}
            alt="team member avatar image"
            top="2%"
            left="4.7%"
            w="19.1%"
            transitionDelay="0.3s"
          />
          <LazyImage
            src={photoTopR}
            alt="team member avatar image"
            top="29.5%"
            left="87.8%"
            w="20.8%"
            transitionDelay="0.6s"
          />
          <LazyImage
            src={photoMidL}
            alt="team member avatar image"
            top="43.7%"
            left="-11.1%"
            w="16.9%"
            transitionDelay="0.6s"
          />
        </Box>
        <LoadOnScroll>
          <LoadWrapper
            w={['100%', '100%', '100%', '50%']}
            pl={[0, 0, 0, '7rem']}
            pb={[80, 80, 80, 142]}
            pr={[0, 0, 0, '50px']}
          >
            <BasicTitle fontSize={['2.5rem', '2.5rem', '3.25rem']} pb={38}>
              It’s like a business plan, but different
            </BasicTitle>
            <BasicText>
              We like to think of the software requirements specification as a
              roadmap for development. When you build a business, everybody
              tells you to have a business plan. It can be altered and changed
              along the way, sure, but you need to have it in place to keep
              track of your progress and be able to plan for what lies ahead.
              And the same rule applies to your software. It’s supposed to be
              part of your general business plan, but the endeavour of
              developing your application is on a level of complexity which
              deserves its own plan. Going into development without one is like
              taking the helm of a ship with no map and a Klingonian-speaking
              crew on board.
            </BasicText>
          </LoadWrapper>
        </LoadOnScroll>
      </Flex>
    </Container>
  </Box>
);

export default BusinessPlan;
