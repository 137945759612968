import { Flex, Heading, Spinner } from '@chakra-ui/core';
import { observer } from 'mobx-react';
import React, { Suspense, useContext, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import * as C from 'components/dataDisplay';
import rootStore from 'stores/rootStore';

const Step = React.lazy(() => import('./specification/Step'));
const Steps = React.lazy(() => import('./specification/Steps'));

const Home: React.FC = observer(() => {
  const { specificationStore } = useContext(rootStore);
  const { id } = useParams();

  useEffect(() => {
    specificationStore.getSpecification(id as string);
  }, [id, specificationStore]);

  if (specificationStore.isLoading) {
    return (
      <Spinner
        pos="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%)"
        size="xl"
      />
    );
  }

  if (specificationStore.isError) {
    return (
      <Flex
        direction="column"
        align="center"
        pos="absolute"
        top="45%"
        left="50%"
        transform="translate(-50%)"
      >
        <Heading>Specification does not exist</Heading>
        <Link to="/">
          <C.Button my="2rem">Back to the landing page</C.Button>
        </Link>
      </Flex>
    );
  }

  return (
    <Flex
      direction={['column-reverse', 'column-reverse', 'row']}
      h={['auto', 'auto', 'calc(100% - 70px)']}
    >
      <Suspense
        fallback={
          <Spinner
            pos="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%)"
            size="xl"
          />
        }
      >
        <Steps />
        <Step />
      </Suspense>
    </Flex>
  );
});

export default Home;
