import React from 'react';

import LoadOnScroll from '../LoadOnScroll';
import { Container, LoadWrapper } from '../shared/styled';
import { BannerText, BlobImage } from './styled';

interface BannerProps {
  imageSrc: string;
  imageAlt: string;
  insideContainer?: boolean;
}

const BlobBanner: React.FC<BannerProps> = ({
  children,
  imageSrc,
  imageAlt,
  insideContainer,
}) => (
  <LoadOnScroll>
    <LoadWrapper pos="relative" mt={['4rem', '8rem', '15rem']}>
      {!insideContainer && <BlobImage src={imageSrc} alt={imageAlt} />}
      <Container>
        {insideContainer && <BlobImage src={imageSrc} alt={imageAlt} />}
        <BannerText
          textAlign={['center', 'center', 'left']}
          fontSize={['2.4rem', '3.2rem', '4.2rem']}
          maxW={insideContainer ? '70rem' : '94rem'}
        >
          {children}
        </BannerText>
      </Container>
    </LoadWrapper>
  </LoadOnScroll>
);

export default BlobBanner;
