import {
  Box,
  Checkbox,
  Flex,
  Heading,
  Link,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/core';
import axios from 'axios';
import { Form, Formik } from 'formik';
import React, { useRef } from 'react';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import * as Yup from 'yup';

import { Endpoints } from 'services/model';

import { ModalButton } from './components/Button';
import { ModalDropdown } from './components/Dropdown';
import { ModalInput } from './components/Input';

const captchaSiteKey = process.env.REACT_APP_HCAPTCHA_SITE_KEY;
if (!captchaSiteKey) {
  throw new Error('REACT_APP_HCAPTCHA_SITE_KEY is not set');
}

type ErrorTextProps = {
  error?: string;
  touched?: boolean;
};

export const ErrorText: React.FC<ErrorTextProps> = ({ error, touched }) => {
  if (error && touched) {
    return (
      <Box w="fit-content" color="red.500">
        {error}
      </Box>
    );
  }
  return null;
};

const PDFDownloadSchema = Yup.object().shape({
  hcaptcha: Yup.string().required('Required'),
  fullName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  role: Yup.string().required('Required'),
  company: Yup.string().required('Required'),
  phone: Yup.string().required('Required'),
  email: Yup.string().email('Must be a valid email').required('Required'),
  isTermsAccepted: Yup.bool().oneOf(
    [true],
    'Terms and Agreements must be accepted',
  ),
});

export type DataFormValuesType = {
  fullName: string;
  role: string;
  company: string;
  phone: string;
  email: string;
  isTermsAccepted: boolean;
  hcaptcha: string;
};

type Props = {
  setDataSent: (isDataSent: boolean) => void;
  setValues: (values: DataFormValuesType) => void;
};

export const DataForm: React.FC<Props> = ({ setDataSent, setValues }) => {
  const captchaRef = useRef<HCaptcha>(null);
  const toast = useToast();
  const startupHouseURL =
    process.env.REACT_APP_BASE_URL ===
    'https://backend.srs-production.startuphouse.dev/v1'
      ? 'https://start-up.house/api/leads'
      : 'https://sdh-homepage-staging.startuphouse.dev/api/leads';

  // TODO: Add endpoint to send data
  const handleSendPDF = async (values: DataFormValuesType) => {
    try {
      await axios.post(
        process.env.REACT_APP_NEWSLETTER_ENDPOINT ||
          Endpoints.NEWSLETTER_SUBSCRIPTION,
        {
          email: values.email,
          source: 'SRS',
          'h-captcha-response': values.hcaptcha,
        },
      );
      toast({
        title: 'Success!',
        description: 'Thank you for subscribing!',
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      console.error(error);
      toast({
        title: 'An error occurred.',
        description: 'There was a problem subscribing to the newsletter',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
    }

    // This needs to be formdata, because backend doesn't support JSON body
    const formData = new FormData();
    formData.append('email', values.email);
    formData.append('full_name', values.fullName);
    formData.append('company_name', values.company);
    formData.append('role', values.role);
    formData.append('terms', String(values.isTermsAccepted));
    formData.append('lead_source', 'SRS');
    formData.append('h-captcha-response', values.hcaptcha);

    try {
      await axios.post(startupHouseURL, formData);
    } catch (error) {
      console.error(error);
    }
    setValues(values);
    setDataSent(true);
  };

  const rolesOptions = [
    { label: 'CEO/Founder', value: 'ceoFounder' },
    { label: 'CTO', value: 'cto' },
    { label: 'Product owner/Head of product', value: 'ownerHead' },
    { label: 'Developer/Engineer', value: 'developerEngineer' },
    { label: 'Project Manager', value: 'projectManager' },
    { label: 'Product Designer', value: 'designer' },
    { label: 'Innovation Manager', value: 'innovationManager' },
    { label: 'Other', value: 'other' },
  ];

  const initialValues: DataFormValuesType = {
    fullName: '',
    role: '',
    company: '',
    phone: '',
    email: '',
    isTermsAccepted: false,
    hcaptcha: '',
  };
  return (
    <>
      <Heading as="h6" width="100%" height="100%" size="lg">
        Download file
      </Heading>
      <Box as="p" mt="1rem">
        We will send you an email with your SRS in PDF file.
      </Box>
      <Box as="p" mb="1rem">
        Please complete the form with details below.
      </Box>
      <Formik
        onSubmit={handleSendPDF}
        validationSchema={PDFDownloadSchema}
        initialValues={initialValues}
      >
        {({ values, handleChange, setFieldValue, errors, touched }) => (
          <Form>
            <Stack spacing={4}>
              <Flex justify="space-between">
                <Box w="48%">
                  <ModalInput
                    name="fullName"
                    label="Full name input"
                    placeholder="Full name"
                    onChange={handleChange}
                    value={values.fullName}
                    error={errors.fullName}
                    touched={touched.fullName}
                  />
                  <ErrorText
                    error={errors.fullName}
                    touched={touched.fullName}
                  />
                </Box>
                <Box w="48%">
                  <ModalDropdown
                    name="role"
                    options={rolesOptions}
                    setFieldValue={setFieldValue}
                    label="Role dropdown"
                    value={values.role}
                    error={errors.role}
                    touched={touched.role}
                    placeholder="Role"
                  />
                  <ErrorText error={errors.role} touched={touched.role} />
                </Box>
              </Flex>
              <Flex justify="space-between">
                <Box w="48%">
                  <ModalInput
                    name="company"
                    label="Company input"
                    value={values.company}
                    onChange={handleChange}
                    placeholder="Company"
                    error={errors.company}
                    touched={touched.company}
                  />
                  <ErrorText error={errors.company} touched={touched.company} />
                </Box>
                <Box w="48%">
                  <ModalInput
                    name="phone"
                    type="number"
                    label="Mobile input"
                    value={values.phone}
                    onChange={handleChange}
                    placeholder="Mobile"
                    error={errors.phone}
                    touched={touched.phone}
                  />
                  <ErrorText error={errors.phone} touched={touched.phone} />
                </Box>
              </Flex>
              <Flex justify="space-between">
                <Box w="60%">
                  <ModalInput
                    name="email"
                    label="Email input"
                    onChange={handleChange}
                    value={values.email}
                    placeholder="Your email"
                    error={errors.email}
                    touched={touched.email}
                  />
                  <ErrorText error={errors.email} touched={touched.email} />
                </Box>
                <ModalButton id="modal-submit-button" w="36%">
                  Send
                </ModalButton>
              </Flex>
              <Checkbox
                size="sm"
                variantColor="green"
                textAlign="left"
                isInvalid={
                  Boolean(errors.isTermsAccepted) && touched.isTermsAccepted
                }
                onChange={(e) =>
                  setFieldValue('isTermsAccepted', e.target.checked)
                }
              >
                <Text
                  ml="1rem"
                  color={
                    errors.isTermsAccepted && touched.isTermsAccepted
                      ? `red.500`
                      : `gray.a300`
                  }
                >
                  I agree to receive the newsletter and commercial information
                  from Startup Development House{' '}
                  <Link
                    href="https://start-up.house/en/privacy-policy?utm_source=srs_generator"
                    target="_blank"
                    rel="noopener noreferrer"
                    color="other.royalBlue"
                    textDecor="underline"
                  >
                    Privacy policy
                  </Link>
                </Text>
              </Checkbox>
              <HCaptcha
                id="captcha-data-form"
                sitekey={captchaSiteKey}
                onVerify={(token) => {
                  setFieldValue('hcaptcha', token);
                }}
                ref={captchaRef}
              />
              <ErrorText error={errors.hcaptcha} touched={touched.hcaptcha} />
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
};
