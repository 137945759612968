import { Box, Image } from '@chakra-ui/core';
import React from 'react';

import waterfallsImage from 'assets/images/landing/waterfalls/waterfalls.svg';

import ImageWithText from '../ImageWithText';

const heading = 'Don’t go<br /> chasing waterfalls…';

const description =
  'While searching “Project Specification Template”, “SRS example”, “SRS template”  or “how to create SRS” in Google, you will most likely encounter massive, confusing software requirements documents with several dozen pages of text and detailed descriptions. That doesn’t really fit into the ‘lean startup’ approach, does it? Those monstrous documents are usually relics of the ‘waterfall’ management approach. It required the project to be planned from A to Z at the very beginning. This led to increased volume of the document which had to include all of the features, user profiles etc. of the final product.';

const Waterfalls = () => {
  return (
    <Box
      pt="5rem"
      backgroundImage="linear-gradient(to bottom, #ffffff, #f9f9f9)"
    >
      <ImageWithText heading={heading} description={description} reversed>
        <Image
          src={waterfallsImage}
          alt="cartoon presenting 3 people working to create a software requirements specification"
          w={['100%', '100%', '100%', '80%']}
          mt={['2rem', '2rem', '2rem', 0]}
          p={['2rem', '2rem', '2rem', 0]}
          transform={['none', 'none', 'none', 'translate(-5rem, 0)']}
        />
      </ImageWithText>
    </Box>
  );
};

export default Waterfalls;
