import { Box, Image } from '@chakra-ui/core';
import React from 'react';

import screenLeftPng from 'assets/images/landing/srs-description/screen-left.png';
import screenLeftWebp from 'assets/images/landing/srs-description/screen-left.webp';
import screenMiddlePng from 'assets/images/landing/srs-description/screen-middle.png';
import screenMiddleWebp from 'assets/images/landing/srs-description/screen-middle.webp';
import screenRightPng from 'assets/images/landing/srs-description/screen-right.png';
import screenRightWebp from 'assets/images/landing/srs-description/screen-right.webp';

import LoadOnScroll from '../LoadOnScroll';
import { StandardLandingProps } from '../model';
import {
  BasicText,
  BasicTitle,
  Container,
  LoadWrapper,
} from '../shared/styled';
import { Blur } from './styled';

interface Props {
  isWebpSupported: boolean;
}

const SrsDescription: React.FC<StandardLandingProps> = ({
  isWebpSupported,
}) => (
  <Box backgroundImage="linear-gradient(to bottom, #ffffff 2%, #f9f9f9)">
    <Container>
      <LoadOnScroll>
        <LoadWrapper>
          <BasicTitle
            fontSize={['2rem', '2.4rem', '3.2rem']}
            as="h2"
            maxW="40rem"
            textAlign="center"
            m="0 auto"
          >
            What is software requirements specification?
          </BasicTitle>
        </LoadWrapper>
      </LoadOnScroll>
      <LoadOnScroll>
        <LoadWrapper>
          <BasicText pt="2.3rem" textAlign="center" maxW="49rem" m="0 auto">
            Whether you are launching your first startup or you are a seasoned
            entrepreneur, you should always begin your work with clearly
            outlining the project’s structure. It will help you communicate with
            the development team and make sure that they deliver what you
            expect, not what they assume you expect.
          </BasicText>
        </LoadWrapper>
      </LoadOnScroll>
    </Container>
    <LoadOnScroll>
      <LoadWrapper pos="relative" pb={105}>
        <Image
          pos="absolute"
          left={0}
          top="10%"
          maxW="30%"
          d={['none', 'none', 'block']}
          src={isWebpSupported ? screenLeftWebp : screenLeftPng}
          alt="image presenting how does the SRS application look like"
        />
        <Image
          right={0}
          top="0"
          maxW="30%"
          pos="absolute"
          d={['none', 'none', 'block']}
          src={isWebpSupported ? screenRightWebp : screenRightPng}
          alt="image presenting how does the SRS application look like"
        />
        <Image
          d="block"
          margin="80px auto 75px"
          w={940}
          maxW={['calc(100% - 4rem)', 'calc(100% - 4rem)', '50%']}
          src={isWebpSupported ? screenMiddleWebp : screenMiddlePng}
          alt="image presenting how does the SRS application look like"
        />
        <Blur maxW={['calc(100% - 4rem)', 'calc(100% - 4rem)', '50%']} />
      </LoadWrapper>
    </LoadOnScroll>
  </Box>
);

export default SrsDescription;
