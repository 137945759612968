import { Box, Image } from '@chakra-ui/core';
import React from 'react';

import appScreenPng from 'assets/images/landing/rivers/app-screen.png';
import dottedSquareDark from 'assets/images/landing/rivers/dotted-square-dark.svg';
import dottedSquareLight from 'assets/images/landing/rivers/dotted-square-light.svg';
import tanBlob from 'assets/images/landing/rivers/tan-blob.svg';

import ImageWithText from '../ImageWithText';
import { AppScreen, TanBlob } from './styled';

const heading = '…please stick<br /> to the rivers';
const description =
  'For a lean startup the perfect solution is to prepare a ‘bare bones’ version of a software requirements specification document. The 20+ startups we have already worked with allowed us to establish a set of rules and best practices which work well for most software projects. These basic rules are listed below and following them should streamline the preparation of your software requirements specification document.';

const Rivers = () => {
  return (
    <Box mt="9rem" pb="30vw" pos="relative">
      <Image
        src={dottedSquareLight}
        alt=""
        pos="absolute"
        d={['none', 'none', 'none', 'block']}
        top="1vw"
        left="0"
        w="5vw"
        h="5vw"
      />
      <ImageWithText
        heading={heading}
        description={description}
        alignImageOutside
      >
        <Box
          pos={['relative', 'relative', 'relative', 'absolute']}
          top={0}
          right={0}
          w={['100%', '100%', '100%', '55%']}
          height={['100vw', '100vw', '100vw', 800]}
        >
          <TanBlob src={tanBlob} alt="" />
          <AppScreen>
            <Image
              src={dottedSquareDark}
              alt=""
              pos="absolute"
              d={['none', 'none', 'none', 'block']}
              right={0}
              top="6.5vw"
              w="5vw"
              h="5vw"
            />
            <Image
              src={appScreenPng}
              alt="screen presenting how SRS application looks like"
              w="100%"
              pos="relative"
            />
          </AppScreen>
        </Box>
      </ImageWithText>
    </Box>
  );
};

export default Rivers;
