import { Heading, Image } from '@chakra-ui/core';
import styled from '@emotion/styled/macro';

import { theme } from 'styles/theme';

export const BannerText = styled(Heading)`
  color: ${theme.colors.customBlack['100']};
  font-weight: 400;

  position: relative;
  z-index: 2;
  padding-top: 15vw;

  & strong {
    font-weight: 600;
  }
`;

export const BlobImage = styled(Image)`
  position: absolute;
  top: 0;
  right: 0;
  max-width: 70%;

  @media (min-width: 1400px) {
    width: 50vw;
    min-width: 64rem;
  }
`;
