import { Heading, Text } from '@chakra-ui/core';
import styled from '@emotion/styled/macro';

import { theme } from 'styles/theme';

export const ImageSectionHeading = styled(Heading)`
  @media (max-width: 62em) {
    br {
      display: none;
    }
  }
`;

export const ImageSectionDescription = styled(Text)`
  opacity: 0.6;
  line-height: 32px;
  color: ${theme.colors.customBlack['100']};
`;
