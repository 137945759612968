import { action, computed, observable } from 'mobx';

import {
  ClockIcon,
  FlagIcon,
  FolderIcon,
  IterationsIcon,
  SettingsIcon,
  SitemapIcon,
  UserIcon,
  WireframesIcon,
} from 'assets/icons';

import { RootStore } from './rootStore';

export enum Steps {
  GOALS_AND_DESCRIPTION = 'Goals and Project Description',
  USER_PERSONAS = 'User Personas',
  USER_STORIES = 'User Stories',
  SITEMAP = 'Sitemap',
  PAGE_DESCRIPTION = 'Page Description',
  WIREFRAMES = 'Wireframes (Optional)',
  REQUIREMENTS = 'Non-functional Requirements',
  TIMELINE_BUDGETS_RISKS = 'Timeline, Budgets, Risks',
  FUTURE_ITERATIONS = 'Future Iterations',
}

export enum StepTooltips {
  GOALS_AND_DESCRIPTION = 'Here you should describe what your startup is all about. Keep it concise, though!',
  USER_PERSONAS = 'User personas help the development team understand different viewpoints of potential users depending on their age, education, occupation etc. Personas are a vital part of the UX design process and creating different types of personas (extreme or average) helps making the design more universal and effective. Try to think of extreme cases that will clearly differentiate the personas.',
  USER_STORIES = 'This section should contain only the high level user stories the website has to support when the MVP is completed. Typically, 7-10 user stories are sufficient for an MVP. Remember to only focus on the Epics (stories focusing on key functional aspects of your website) and not delve into details. The dev team will break the Epics down later in backlog grooming sessions. What you are writing here is the bare minimum necessary to start a productive conversation about the development process. User stories should have form similar to: ‘As a [type of user], I want [goal], so that [reason].’.',
  SITEMAP = 'A sitemap is a structure layout of your website which shows the hierarchy of all the screens and pages. You don’t want more than 10 pages in your MVP - keep it nice and concise.',
  PAGE_DESCRIPTION = 'This is where you can define what each page will contain. You can order the contents in important-first order so that developers will know what to focus on.',
  WIREFRAMES = 'Wireframes are simple functional layouts of your site’s pages. They show the relative position and size of every object on the screen and let you test your product hands-on before a single line of code is written. Not only does it save you money, but also gives you an opportunity to test and improve your vision of your startup’s functionality and UX. There are several wireframing tools on the market - we use UXPIN.com for our projects.',
  REQUIREMENTS = 'This section contains any technical details which you want your project to have. This is just an example, so for most projects the list of technologies will be a lot shorter than the one below. If you don’t have any specific requirements or you don’t know enough to decide, leave this part to the dev team - they will know what to do!',
  TIMELINE_BUDGETS_RISKS = 'Here you should specify general info about your project. Answer three important questions: what is the goal of the project? What problems does the project solve? What is the vision? Remember that answers should be very clear and understandable, so that when you read them in a few months, you will exactly know what your project is about.',
  FUTURE_ITERATIONS = 'You should list any future plans for the website’s development so that developers can make sure those future iterations will be easy to implement.',
}

export interface StepProps {
  id: number;
  label: Steps;
  tooltip: StepTooltips;
  icon: any;
  value: number;
}

export class StepsStore {
  rootStore: RootStore;
  steps: StepProps[] = observable([
    {
      id: 1,
      label: Steps.GOALS_AND_DESCRIPTION,
      icon: FlagIcon,
      value: 0,
      tooltip: StepTooltips.GOALS_AND_DESCRIPTION,
    },
    {
      id: 2,
      label: Steps.USER_PERSONAS,
      icon: UserIcon,
      value: 0,
      tooltip: StepTooltips.USER_PERSONAS,
    },
    {
      id: 3,
      label: Steps.USER_STORIES,
      icon: FolderIcon,
      value: 0,
      tooltip: StepTooltips.USER_STORIES,
    },
    {
      id: 4,
      label: Steps.SITEMAP,
      icon: SitemapIcon,
      value: 0,
      tooltip: StepTooltips.SITEMAP,
    },
    {
      id: 5,
      label: Steps.PAGE_DESCRIPTION,
      icon: FolderIcon,
      value: 0,
      tooltip: StepTooltips.PAGE_DESCRIPTION,
    },
    {
      id: 6,
      label: Steps.WIREFRAMES,
      icon: WireframesIcon,
      value: 0,
      tooltip: StepTooltips.WIREFRAMES,
    },
    {
      id: 7,
      label: Steps.REQUIREMENTS,
      icon: SettingsIcon,
      value: 0,
      tooltip: StepTooltips.REQUIREMENTS,
    },
    {
      id: 8,
      label: Steps.TIMELINE_BUDGETS_RISKS,
      icon: ClockIcon,
      value: 0,
      tooltip: StepTooltips.TIMELINE_BUDGETS_RISKS,
    },
    {
      id: 9,
      label: Steps.FUTURE_ITERATIONS,
      icon: IterationsIcon,
      value: 0,
      tooltip: StepTooltips.FUTURE_ITERATIONS,
    },
  ]);
  @observable activeStep: StepProps = this.steps[0];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action
  setActiveStep(userStep: StepProps): void {
    this.activeStep = userStep;
  }

  @action
  setNextStep(): void {
    this.activeStep = this.steps.find(
      (step) => step.id === this.activeStep.id + 1,
    )!;
  }

  @computed
  get numberOfSteps(): number {
    return this.steps.length;
  }

  @computed
  get activeStepNumber(): number {
    return this.activeStep.id;
  }

  @computed
  get completedSteps(): number {
    let result = 0;
    this.steps.forEach((i) => i.value === 100 && result++);

    return result;
  }

  @computed
  get completedStepsPercentage(): number {
    const stepLength = this.numberOfSteps;
    const completedSteps = this.completedSteps;

    return Math.trunc((completedSteps / stepLength) * 100);
  }

  @action
  updateValues(percentages: number, step: Steps): void {
    const currentStep = this.steps.filter((s) => s.label === step);
    currentStep[0].value = percentages;
  }
}
