import { Box } from '@chakra-ui/core';
import React from 'react';

import LoadOnScroll from 'pages/LandingPage/LoadOnScroll';
import { LoadWrapperFlex } from 'pages/LandingPage/shared/styled';

import chartData from './chartData.json';
import ChartItem from './ChartItem';

const Chart = () => (
  <Box maxW={['100%', '100%', '100%', '60%']}>
    {chartData.map((chartItem, index) => (
      <LoadOnScroll key={index}>
        {(_: any, e: any) => {
          const { title, percentage } = chartItem;
          return (
            <LoadWrapperFlex py={2} align="center">
              <ChartItem
                title={title}
                percentage={percentage}
                dark={index === 0}
                animStart={e.type === 'start'}
              />
            </LoadWrapperFlex>
          );
        }}
      </LoadOnScroll>
    ))}
  </Box>
);

export default Chart;
