import { Box, Flex, Heading, Text } from '@chakra-ui/core';
import React from 'react';
import ScrollIntoView from 'react-scroll-into-view';
import { Controller, Scene } from 'react-scrollmagic';

import LoadOnScroll from '../LoadOnScroll';
import { Container, LoadWrapper, LoadWrapperListItem } from '../shared/styled';
import sections from './sectionData.json';
import {
  LineThrough,
  NavList,
  NavListContainer,
  ParagraphText,
  StyledListItem,
} from './styled';

const triggerHook = 0.2;

const Paragraphs = () => (
  <LoadOnScroll>
    <LoadWrapper backgroundColor="#caf0fe" pb={[150, 150, 330]}>
      <Container>
        <Flex direction={['column', 'column', 'row']}>
          <NavListContainer>
            <NavList>
              {sections.map((section) => (
                <LoadOnScroll key={section.id}>
                  <LoadWrapperListItem>
                    <ScrollIntoView
                      selector={`.paragraph-text-${section.id}`}
                      style={{ cursor: 'pointer' }}
                    >
                      <StyledListItem
                        className={`left-menu-item-${section.id}`}
                        role="button"
                        tabIndex={0}
                      >
                        {section.title}
                        <LineThrough />
                      </StyledListItem>
                    </ScrollIntoView>
                  </LoadWrapperListItem>
                </LoadOnScroll>
              ))}
            </NavList>
          </NavListContainer>
          <Box w={['100%', '100%', '50%']}>
            {sections.map((section) => (
              <LoadOnScroll key={section.id}>
                <LoadWrapper>
                  <Controller>
                    <Scene
                      triggerHook={triggerHook}
                      classToggle={[`.paragraph-text-${section.id}`, 'entered']}
                    >
                      {/* The scenes need a child element, so the empty span provides that */}
                      <span className="scroll-trigger" />
                    </Scene>
                    <Scene
                      triggerHook={triggerHook}
                      classToggle={[`.left-menu-item-${section.id}`, 'entered']}
                    >
                      <ParagraphText
                        className={`paragraph-text-${section.id}`}
                        mt={['30px', '30px', '7px']}
                      >
                        <Heading
                          fontSize={['lg', 'xl', 'xl2', 'xl3', 'xxl']}
                          pb="10px"
                        >
                          {section.title}
                        </Heading>
                        <Text
                          fontSize={['md', 'md2', 'md2', 'lg', 'xl']}
                          lineHeight={['30px', '38px', '38px', '48px']}
                        >
                          {section.paragraph}
                        </Text>
                      </ParagraphText>
                    </Scene>
                    <Scene
                      triggerHook={triggerHook}
                      classToggle={[`.left-menu-item-${section.id}`, 'passed']}
                    >
                      {/* The scenes need a child element, so the empty span provides that */}
                      <span className="scroll-trigger" />
                    </Scene>
                    <Scene
                      triggerHook={triggerHook}
                      classToggle={[`.paragraph-text-${section.id}`, 'passed']}
                    >
                      {/* The scenes need a child element, so the empty span provides that */}
                      <span className="scroll-trigger" />
                    </Scene>
                  </Controller>
                </LoadWrapper>
              </LoadOnScroll>
            ))}
          </Box>
        </Flex>
      </Container>
    </LoadWrapper>
  </LoadOnScroll>
);

export default Paragraphs;
