import { createContext } from 'react';

import { ApiStore } from './apiStore';
import { SpecificationStore } from './specificationStore';
import { StepsStore } from './stepsStore';

export class RootStore {
  apiStore = new ApiStore(this);
  stepsStore = new StepsStore(this);
  specificationStore = new SpecificationStore(this, this.apiStore);
}

export default createContext(new RootStore());
