import React, { useEffect, useState } from 'react';
import { Controller, Scene } from 'react-scrollmagic';

interface Props {
  children: React.ReactNode;
}

const LoadOnScroll: React.FC<Props> = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);

  const checkIfMobile = () => setIsMobile(window.innerWidth <= 600);

  useEffect(() => {
    window.addEventListener('resize', checkIfMobile);
    checkIfMobile();
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  return isMobile ? (
    <>
      {typeof children === 'function'
        ? children(null, { type: 'start' })
        : children}
    </>
  ) : (
    <Controller>
      <Scene triggerHook={1} classToggle="loaded" reverse={false}>
        {children}
      </Scene>
    </Controller>
  );
};

export default LoadOnScroll;
