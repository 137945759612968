import { CSSReset, ThemeProvider } from '@chakra-ui/core';
import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import Header from 'components/layout/Header';
import LandingPage from 'pages/LandingPage';
import Specification from 'pages/Specification';

import { GlobalStyles } from './styles/GlobalStyles';
import { theme } from './styles/theme';
import { history } from './utils/history';

const App: React.FC = () => (
  <ThemeProvider theme={theme}>
    <CSSReset />
    <Router history={history}>
      <GlobalStyles />
      <Header />
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/specification/:id" component={Specification} />
        <Route path="/">
          <Redirect to="/" />
        </Route>
      </Switch>
    </Router>
  </ThemeProvider>
);

export default App;
