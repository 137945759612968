import { Box, Flex } from '@chakra-ui/core';
import React, { ReactNode } from 'react';

import LoadOnScroll from '../LoadOnScroll';
import { Container, LoadWrapper } from '../shared/styled';
import { ImageSectionDescription, ImageSectionHeading } from './styled';

interface Props {
  reversed?: boolean;
  alignImageOutside?: boolean;
  children?: ReactNode;
  heading: string;
  description: string;
}

const ImageWithText: React.FC<Props> = ({
  children,
  reversed,
  heading,
  description,
  alignImageOutside,
}) => (
  <LoadOnScroll>
    <LoadWrapper py={['2rem', '5rem', '9rem']} pos="relative">
      {alignImageOutside && children}
      <Container>
        <Flex
          direction={[
            'column-reverse',
            'column-reverse',
            'column-reverse',
            reversed ? 'row-reverse' : 'row',
          ]}
          align="center"
        >
          <Box
            textAlign={['center', 'center', 'center', 'left']}
            w={['100%', '100%', '100%', alignImageOutside ? '32%' : '37%']}
          >
            <ImageSectionHeading
              fontSize={['xxl', 'xxl2', 'xxxl', 'xxxl2']}
              pb="1.5rem"
              dangerouslySetInnerHTML={{ __html: heading }}
            />
            <ImageSectionDescription
              fontSize={['md', 'md', 'md', 'md2']}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </Box>
          <Box w={['100%', '100%', '100%', '63%']}>
            {!alignImageOutside && children}
          </Box>
        </Flex>
      </Container>
    </LoadWrapper>
  </LoadOnScroll>
);

export default ImageWithText;
