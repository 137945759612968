import { Heading, Image } from '@chakra-ui/core';
import styled from '@emotion/styled/macro';

import { theme } from 'styles/theme';

export const DocumentStructureTitle = styled(Heading)`
  padding-bottom: 1.5rem;
  line-height: 64px;
  color: ${theme.colors.customBlack['100']};

  @media (max-width: 62em) {
    br {
      display: none;
    }
  }

  @media (min-width: 1600px) {
    font-size: ${theme.fontSizes.freakingHuge} !important;
    line-height: 74px;
  }
`;

export const DocumentStructureImage = styled(Image)`
  @media (min-width: 1400px) {
    width: 110%;
    max-width: none;
  }

  @media (min-width: 1600px) {
    width: 120%;
    max-width: none;
  }

  @media (min-width: 1920px) {
    width: 130%;
  }
`;
