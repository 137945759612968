import { Heading, Image } from '@chakra-ui/core';
import React from 'react';

import dottedSquare from 'assets/images/landing/banners/dotted-square.svg';
import halfCircle from 'assets/images/landing/banners/half-circle.svg';

import LoadOnScroll from '../LoadOnScroll';
import CascadingText from '../shared/CascadingText';
import { Container, LoadWrapper } from '../shared/styled';

const bannerText =
  'Software requirements specification will help you hedge the risk of failure and avoid some of the very common early-stage mistakes.';
const bannerEmText = 'Building a solid foundation for future work is key.';

const GreenBlobBanner = () => {
  return (
    <LoadOnScroll>
      <LoadWrapper bg="green.500" pos="relative">
        <Image
          src={dottedSquare}
          alt=""
          pos="absolute"
          top="20%"
          left={0}
          width="6%"
        />
        <Image
          src={halfCircle}
          alt=""
          pos="absolute"
          right={0}
          bottom="8%"
          width="5%"
        />
        <Container>
          <Heading
            as="h2"
            color="white"
            py={[100, 100, 150, 246]}
            fontSize={['xl', 'xl', 'xxl', '60px']}
            textAlign="center"
            fontWeight={400}
          >
            <CascadingText text={bannerText} />
            <CascadingText text={bannerEmText} initialDelay={2} emphasis />
          </Heading>
        </Container>
      </LoadWrapper>
    </LoadOnScroll>
  );
};

export default GreenBlobBanner;
