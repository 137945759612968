import { Box, Flex } from '@chakra-ui/core';
import React from 'react';

import card1Icon from 'assets/images/landing/instructions/card1.svg';
import card2Icon from 'assets/images/landing/instructions/card2.svg';
import card3Icon from 'assets/images/landing/instructions/card3.svg';

import { Container } from '../shared/styled';
import Card from './Card';

const cardData = [
  {
    title:
      'How to write software requirements specification (SRS) for startups?',
    text:
      '3 new startups are founded every second, that makes it 11.000 an hour or 260.000 a day. It means that you are very likely to fall behind your new competition if you don’t develop fast enough. It is crucial to stay ahead of the market and a good plan (vide SRS) is what you need under your belt.',
    iconSrc: card1Icon,
    iconAlt: 'pen icon',
  },
  {
    title: 'Choose the right<br /> tech stack',
    text:
      'A specification sheet contains all the functional requirements of your future product. Having this information in place allows you to make the right choices in terms of which technology to use. You need to consider speed, scaling abilities, cost of future maintenance and integrations to avoid unnecessarily complicating your app on one hand, and to make sure it is ready for rapid growth on the other.',
    iconSrc: card2Icon,
    iconAlt: 'tech stack icon',
  },
  {
    title: 'Choose the<br /> right team',
    text:
      'A well-written SRS allows you to clarify your needs in terms of technology stack and scale of the project, which in turn makes your recruitment needs crystal clear. Then, you can build a team whose competence will be a perfect match for the project and make sure that your product is delivered. After all, before you find the right people for the job, you need to know what the job is, right?',
    iconSrc: card3Icon,
    iconAlt: 'team member icon',
  },
];

const Instructions = () => (
  <Box pt="12.3rem">
    <Container maxW="1492px !important">
      <Flex direction={['column', 'column', 'column', 'row']}>
        {cardData.map((card, index) => (
          <Card
            key={index}
            pt={['3rem', '3rem', '3rem', index === 1 ? '18.4rem' : 0]}
            transitionDelay={`${index * 0.2}s`}
            {...card}
          />
        ))}
      </Flex>
    </Container>
  </Box>
);

export default Instructions;
