import { css, Global } from '@emotion/core';
import React from 'react';

export const GlobalStyles: React.FC = () => (
  <Global
    styles={css`
      html {
        box-sizing: border-box;
        height: 100%;
        font-size: 14px;
      }

      *,
      *::before,
      *::after {
        box-sizing: inherit;
      }

      body {
        margin: 0;
        padding: 0;
        height: 100%;
      }

      #root {
        height: 100%;
      }
    `}
  />
);
