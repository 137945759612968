import { Box, Image } from '@chakra-ui/core';
import styled from '@emotion/styled/macro';

export const SrsDescriptionImage = styled(Image)`
  width: 100%;
  @media (min-width: 1660px) {
    transform: translate(0, -5%);
  }
`;

export const Blur = styled(Box)`
  width: 940px;
  max-width: 50%;
  margin: 0 auto;
  height: 27px;
  -webkit-filter: blur(34px);
  filter: blur(34px);
  background-color: #dbd4cf;
`;
