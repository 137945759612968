import { Box, Flex, Heading, Image, ListItem, Text } from '@chakra-ui/core';
import styled from '@emotion/styled/macro';

import { theme } from 'styles/theme';

export const Container = styled(Box)`
  max-width: 1440px;
  padding: 0 5rem;
  margin: 0 auto;
  position: relative;

  @media (max-width: 480px) {
    padding: 0 2rem;
  }
`;

export const BasicTitle = styled(Heading)`
  color: ${theme.colors.customBlack['100']};
`;

export const BasicText = styled(Text)`
  font-size: 1.4rem;
  color: ${theme.colors.customBlack['100']};
  opacity: 0.6;
`;

const loadWrapperStyles = `
  @media (min-width: 600px) {

    transform: translate3d(0, 100px, 0);
    opacity: 0;
    transition: transform 1s, opacity 2s;
    
    &.loaded {
      opacity: 1;
      transform: none;
    }
  }
`;

export const LoadWrapper = styled(Box)`
  ${loadWrapperStyles}
`;

export const LoadWrapperListItem = styled(ListItem)`
  ${loadWrapperStyles}
`;

export const LoadWrapperFlex = styled(Flex)`
  ${loadWrapperStyles}
`;

export const LoadWrapperSpan = styled.span`
  display: inline-block;
  ${loadWrapperStyles}
`;

export const EmphasizedLoadWrapperSpan = styled.span`
  ${loadWrapperStyles}
  display: inline-block;
  border-bottom: 1px solid #fff;
  font-weight: 600;
`;

export const DecorImage = styled(Image)`
  position: absolute;
  display: block;
`;
