import axios, { AxiosInstance } from 'axios';

const httpClient = (): AxiosInstance => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });

  instance.interceptors.request.use();

  return instance;
};

export default httpClient();
