import { Box } from '@chakra-ui/core';
import React from 'react';

import blueBlob from 'assets/images/landing/banners/blue-blob.svg';

import BlobBanner from '../BlobBanner';
import CascadingText from '../shared/CascadingText';

const bannerText =
  'Writing an SRS the lean way is totally different to what you may expect. Don’t get yourself caught up in unnecessary details - <strong>agility&nbsp;FTW!</strong>';

const BlueBlobBanner = () => {
  return (
    <Box pb={['4rem', '8rem', '20rem']}>
      <BlobBanner imageSrc={blueBlob} imageAlt="" insideContainer>
        <CascadingText text={bannerText} />
      </BlobBanner>
    </Box>
  );
};

export default BlueBlobBanner;
