import { Text } from '@chakra-ui/core';
import styled from '@emotion/styled/macro';

import { theme } from 'styles/theme';

export const BannerQuote = styled(Text)`
  color: ${theme.colors.white};
  font-weight: 600;
`;

export const BannerQuoteAuthor = styled(Text)`
  font-size: ${theme.fontSizes.xl};
  font-weight: 500;
  color: ${theme.colors.customBlack['100']};
  opacity: 0.5;
`;
