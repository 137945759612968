import { useToast } from '@chakra-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import supportsWebp from 'supports-webp';

import Footer from 'components/layout/Footer';
import rootStore from 'stores/rootStore';

import BlueBlobBanner from './BlueBlobBanner';
import BusinessPlan from './BusinessPlan';
import DidYouKnow from './DidYouKnow';
import DocumentStructure from './DocumentStructure';
import GreenBlobBanner from './GreenBlobBanner';
import Instructions from './Instructions';
import Intro from './Intro';
import Paragraphs from './Paragraphs';
import PhotoBanner from './PhotoBanner';
import Rivers from './Rivers';
import SrsDescription from './SrsDescription';
import Waterfalls from './Waterfalls';

const LandingPage: React.FC = () => {
  const [isWebpSupported, setIsWebpSupported] = useState(true);
  const { specificationStore } = useContext(rootStore);
  let history = useHistory();
  const toast = useToast();

  useEffect(() => {
    // this is a convoluted way to avoid race conditions
    // but for now we're stuck with it
    let didCancel = false;

    async function checkIsWebpSupported() {
      const result = await supportsWebp;

      if (!didCancel) {
        setIsWebpSupported(result);
      }
    }

    checkIsWebpSupported();

    return () => {
      didCancel = true;
    };
    //eslint-disable-next-line
  }, []);

  const generateSpecification = async () => {
    await specificationStore.createSpecification();

    if (specificationStore.isError) {
      toast({
        title: 'Error',
        description: 'There is a problem with creating the specification',
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'top-right',
      });
    } else {
      history.push(`/specification/${specificationStore.specificationId}`);
    }
  };

  return (
    <>
      <Intro
        generateSpecification={generateSpecification}
        isWebpSupported={isWebpSupported}
      />
      <SrsDescription isWebpSupported={isWebpSupported} />
      <BusinessPlan isWebpSupported={isWebpSupported} />
      <GreenBlobBanner />
      <DidYouKnow />
      <PhotoBanner isWebpSupported={isWebpSupported} />
      <Instructions />
      <BlueBlobBanner />
      <Waterfalls />
      <Rivers />
      <Paragraphs />
      <DocumentStructure generateSpecification={generateSpecification} />
      <Footer />
    </>
  );
};

export default LandingPage;
