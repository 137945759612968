export enum Endpoints {
  ROOT = '',
  SPECIFICATIONS = 'specifications',
  PERSONAS = 'personas',
  PDF = 'pdf',
  PHOTO = 'photo',
  WIREFRAMES = 'wireframes',
  SITEMAP = 'sitemap',
  REQUIREMENTS = 'requirements',
  GOALS_AND_PROJECT_DESCRIPTION = 'goals-and-project-description',
  TIMELINE_BUDGETS_RISKS = 'timeline-budgets-risks',
  FUTURE_ITERATIONS = 'future-iterations',
  USER_STORIES = 'user-stories',
  PAGE_DESCRIPTION = 'page-description',
  NEWSLETTER_SUBSCRIPTION = 'https://start-up.house/api/subscribers',
}

export enum NonFunctionalRequirementType {
  TECHNOLOGY_BACKEND = 'TECHNOLOGY_BACKEND',
  TECHNOLOGY_FRONTEND = 'TECHNOLOGY_FRONTEND',
  SPECIFICATION_AND_CONFIGURATION = 'SPECIFICATION_AND_CONFIGURATION',
  THIRD_PARTY_LIBRARIES = 'THIRD_PARTY_LIBRARIES',
  SECURITY = 'SECURITY',
  DEVOPS_METODOLOGY = 'DEVOPS_METODOLOGY',
}

export enum PersonaSexType {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum PersonaFamilyStatusType {
  SINGLE = 'SINGLE',
  MARRIED_NO_KIDS = 'MARRIED_NO_KIDS',
  MARRIED_YOUNG_KIDS = 'MARRIED_YOUNG_KIDS',
  MARRIED_OLD_KIDS = 'MARRIED_OLD_KIDS',
  SENIOR = 'SENIOR',
  OTHER = 'OTHER',
}

export enum PersonaEducationType {
  PRE_ACADEMIC = 'PRE_ACADEMIC',
  BACHELOR = 'BACHELOR',
  MASTER = 'MASTER',
  DOCTORAL = 'DOCTORAL',
  OTHER = 'OTHER',
}

export enum PersonaCompanySizeType {
  ONE_TO_TWO = 'ONE_TO_TWO',
  THREE_TO_TEN = 'THREE_TO_TEN',
  LESS_THAN_50 = 'LESS_THAN_50',
  LESS_THAN_250 = 'LESS_THAN_250',
  LESS_THAN_1000 = 'LESS_THAN_1000',
  MORE_THAN_1000 = 'MORE_THAN_1000',
}

export enum PersonaTrendsType {
  NEGATIVE = 'NEGATIVE',
  POSITIVE = 'POSITIVE',
}

export enum PersonaValuesType {
  SKILLS = 'SKILLS',
  REACH = 'REACH',
  MOTIVATION = 'MOTIVATION',
}

export interface Specification {
  pageDescriptions: PageDescriptionParams[];
  requirements: NonFunctionalRequirementParams[];
  userStories: UserStoryParams[];
  personas: UserPersonaParams[];
  futureIterations: FutureIterationParams[];
  sitemaps: SitemapsParams[];
  wireframes: WireframesParams[];
  goalsAndProjectDescription: GoalsAndProjectDescriptionsParams;
  timelineBudgetsRisks: TimelineBudgetsRisksParams;
}

export interface CreatePageDescriptionReq {
  name?: string;
  structures: string[];
}

export interface PageDescriptionParams extends CreatePageDescriptionReq {
  id: string;
  createdAt?: string;
}

export interface GoalsAndProjectDescriptionsParams {
  goal: string;
  problems: string;
  vision: string;
}

export interface TimelineBudgetsRisksParams {
  timeline: string;
  budgets: string;
  risks: string;
}

export interface CreateNonFunctionalRequirementReq {
  type?: NonFunctionalRequirementType;
  value?: string;
  createdAt?: string;
}

export interface NonFunctionalRequirementParams
  extends CreateNonFunctionalRequirementReq {
  id: string;
}

export interface CreateUserStoryReq {
  role?: string;
  target?: string;
  result?: string;
}

export interface UserStoryParams extends CreateUserStoryReq {
  id: string;
  createdAt?: string;
}

export interface CreateUserPersonaReq {
  title: string;
  fullName: string;
  position: string;
  age: number | null;
  location: string;
  industry: string;
  brands: string;
  photo: string | null;
  sex: PersonaSexType;
  companySize: PersonaCompanySizeType;
  education: PersonaEducationType;
  familyStatus: PersonaFamilyStatusType;
  personaJobs: {
    title: string;
    name: string;
    description: string;
    placeholder?: string;
    color?: string;
  }[];
  personaValues: {
    type: PersonaValuesType;
    label: string;
    name: string;
    value: number;
  }[];
  personaTrends: {
    type: PersonaTrendsType;
    value: string;
    label: string;
  }[];
}

export interface UserPersonaParams extends CreateUserPersonaReq {
  id: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface CreateSitemapReq {
  file: File;
}

export interface SitemapsParams {
  id: string;
  sitemap: string;
}

export interface CreateWireframesReq {
  file: string;
}

export interface WireframesParams {
  id: string;
  wireframe: string;
  createdAt?: string;
}

export interface CreateFutureIterationReq {
  feature?: string;
  description?: string;
}

export interface FutureIterationParams extends CreateFutureIterationReq {
  id: string;
  createdAt?: string;
}

export type PagesTypes =
  | PageDescriptionParams
  | GoalsAndProjectDescriptionsParams
  | TimelineBudgetsRisksParams
  | NonFunctionalRequirementParams
  | UserStoryParams
  | UserPersonaParams
  | SitemapsParams
  | WireframesParams
  | FutureIterationParams;

export type CreateTypes =
  | CreatePageDescriptionReq
  | GoalsAndProjectDescriptionsParams
  | TimelineBudgetsRisksParams
  | CreateNonFunctionalRequirementReq
  | CreateUserPersonaReq
  | CreateUserStoryReq
  | CreateFutureIterationReq;

export type NonFunctionalRequirementsTypes =
  | 'TECHNOLOGY_BACKEND'
  | 'TECHNOLOGY_FRONTEND'
  | 'SPECIFICATION_AND_CONFIGURATION'
  | 'THIRD_PARTY_LIBRARIES'
  | 'SECURITY'
  | 'DEVOPS_METODOLOGY';
