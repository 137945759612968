import React from 'react';

import LoadOnScroll from '../LoadOnScroll';
import { EmphasizedLoadWrapperSpan, LoadWrapperSpan } from './styled';

interface Props {
  text: string;
  delay?: number;
  initialDelay?: number;
  emphasis?: boolean;
}

const CascadingText: React.FC<Props> = ({
  text,
  delay,
  initialDelay,
  emphasis,
}) => {
  const words = text.split(' ');
  return (
    <>
      {words.map((word, index) => (
        <LoadOnScroll key={index}>
          {emphasis ? (
            <EmphasizedLoadWrapperSpan
              style={{
                transitionDelay: `${
                  index * (delay || 0.05) + (initialDelay || 0)
                }s`,
              }}
              dangerouslySetInnerHTML={{ __html: `${word}&nbsp;` }}
            />
          ) : (
            <LoadWrapperSpan
              style={{
                transitionDelay: `${
                  index * (delay || 0.05) + (initialDelay || 0)
                }s`,
              }}
              dangerouslySetInnerHTML={{ __html: `${word}&nbsp;` }}
            />
          )}
        </LoadOnScroll>
      ))}
    </>
  );
};

export default CascadingText;
