import { Box, Text } from '@chakra-ui/core';
import styled from '@emotion/styled/macro';

import { theme } from 'styles/theme';

export const PercentageBar = styled(Box)`
  height: 20px;
  border-radius: 10px;
  background-color: ${theme.colors.green['500']};
  transition: width 1.5s;
`;

export const ChartLabel = styled(Text)`
  display: inline-block;
  width: 226px;
  font-weight: 600;
  text-align: right;
  color: ${theme.colors.customBlack['100']};
  margin-right: 16px;
`;

export const ChartPercentage = styled(Text)`
  display: inline-block;
  font-weight: 600;
  color: ${theme.colors.customBlack['100']};
  font-size: ${theme.fontSizes.md};
  margin-left: 16px;
`;
