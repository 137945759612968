import { Box, CircularProgress, Flex } from '@chakra-ui/core';
import React from 'react';

import { CheckIcon } from 'assets/icons';
import { theme } from 'styles/theme';

import * as S from './styled';

interface Props {
  value: number;
}

const { green } = theme.colors;

export const Progress: React.FC<Props> = ({ value }) => {
  if (value === 0) {
    return (
      <Box
        w="2.2rem"
        h="2.2rem"
        mr="0.29rem"
        rounded="full"
        border="1.8px dashed #919da9"
      />
    );
  }

  return (
    <CircularProgress
      value={value}
      color="green"
      trackColor="green"
      w="2.88rem"
      thickness={0.1}
      capIsRound
    >
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        style={{ display: 'none' }}
      >
        <symbol id="wave">
          <path d="M420,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C514,6.5,518,4.7,528.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H420z" />
          <path d="M420,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C326,6.5,322,4.7,311.5,2.7C304.3,1.4,293.6-0.1,280,0c0,0,0,0,0,0v20H420z" />
          <path d="M140,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C234,6.5,238,4.7,248.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H140z" />
          <path d="M140,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C46,6.5,42,4.7,31.5,2.7C24.3,1.4,13.6-0.1,0,0c0,0,0,0,0,0l0,20H140z" />
        </symbol>
      </svg>

      <S.Box>
        <S.Water
          bg="green.200"
          transform={`translateY(${value === 100 ? 0 : 110 - value}%)`}
        >
          {value === 100 ? (
            <Flex h="100%" align="center" justify="center">
              <Box as={CheckIcon} />
            </Flex>
          ) : (
            <>
              <S.WaterWaveBack fill={green[100]} viewBox="0 0 560 20">
                <use xlinkHref="#wave" />
              </S.WaterWaveBack>
              <S.WaterWaveFront fill={green[200]} viewBox="0 0 560 20">
                <use xlinkHref="#wave" />
              </S.WaterWaveFront>
            </>
          )}
        </S.Water>
      </S.Box>
    </CircularProgress>
  );
};
