import { Box, List } from '@chakra-ui/core';
import styled from '@emotion/styled/macro';

import { theme } from 'styles/theme';

export const StyledListItem = styled.span`
  position: relative;
  display: inline-block;
  font-size: 1.85rem;
  line-height: 3.2rem;
  cursor: pointer;
  font-weight: 600;
  transition: margin 0.5s;
  margin: 0.5rem 0;

  @media (min-width: 1600px) {
    font-size: 2.35rem;
    line-height: 4.2rem;
  }

  @media (min-width: 1920px) {
    font-size: 2.85rem;
    line-height: 5.2rem;
  }

  &:not(.passed).entered {
    margin-left: 2rem;

    span {
      width: 100%;
    }
  }
`;

export const LineThrough = styled.span`
  position: absolute;
  top: 52%;
  left: 0;
  width: 0;
  height: 4%;
  background-color: ${theme.colors.black};
  transition: width 0.4s cubic-bezier(0.67, 0.03, 0.79, 0.31);
`;

export const ParagraphText = styled(Box)`
  font-size: 1.8rem;
  transition: opacity 0.2s;
  opacity: 0.4;
  padding-top: 7.8rem;

  &.entered {
    opacity: 1;
  }

  &.entered.passed {
    opacity: 0.4;
  }

  @media (min-width: 1600px) {
    padding-top: 9rem;
    font-size: 2.1rem;
  }

  @media (min-width: 1920px) {
    padding-top: 12rem;
    font-size: 2.42rem;
  }

  @media (min-width: 2200px) {
    padding-top: 200px;
  }
`;

export const NavListContainer = styled.nav`
  width: 50%;
  padding-top: 7rem;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media (min-width: 1600px) {
    padding-top: 8.2rem;
  }

  @media (min-width: 1920px) {
    padding-top: 11.2rem;
  }

  @media (min-width: 2200px) {
    padding-top: 200px;
  }
`;

export const NavList = styled(List)`
  position: sticky;
  top: 7rem;

  @media (min-width: 1600px) {
    top: 8.2rem;
  }
  @media (min-width: 1920px) {
    top: 11.2rem;
  }
  @media (min-width: 2200px) {
    top: 200px;
  }
`;
