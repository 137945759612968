import { Input, InputProps } from '@chakra-ui/core';
import React from 'react';

type Props = Omit<InputProps, 'css'> & {
  label: string;
  name?: string;
  error?: string;
  touched?: boolean;
};

export const ModalInput: React.FC<Props> = ({
  label,
  name,
  onChange,
  placeholder,
  value,
  error,
  touched,
  ...rest
}) => {
  return (
    <Input
      h="4rem"
      aria-label={label}
      name={name}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      isInvalid={error ? touched : false}
      errorBorderColor="red.500"
      _placeholder={{
        color: error && touched ? 'red.500' : 'gray.a300',
      }}
      {...rest}
    />
  );
};
