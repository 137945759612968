import { Tooltip as ChakraTooltip } from '@chakra-ui/core';
import { PopperProps } from '@chakra-ui/core/dist/Popper';
import React from 'react';

type Props = {
  label: string;
  children: React.ReactNode;
  placement?: PopperProps['placement'];
};

export const Tooltip: React.FC<Props> = ({ children, label, placement }) => {
  return (
    <ChakraTooltip
      aria-label={label}
      label={label}
      placement={placement}
      bg="white"
      color="black"
      p="0.5rem 1rem"
      rounded="small"
      zIndex={20}
    >
      {children}
    </ChakraTooltip>
  );
};
