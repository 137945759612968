import { Box } from '@chakra-ui/core';
import styled from '@emotion/styled/macro';

import { theme } from 'styles/theme';

export const VerticalDivider = styled.span`
  display: block;
  width: 1px;
  height: 52px;
  background-color: #000;
  opacity: 0.1;
`;

export const CheckboxWrapper = styled(Box)`
  height: 60px;
  margin-bottom: 20px;

  & label {
    div:first-of-type {
      margin-top: 2px;
    }

    div:not(:first-of-type) {
      margin-left: 21px;
    }
  }
`;

export const HeaderWrapper = styled(Box)`
  transition: background-color 0.3s;
  .scroll-magic-logo {
    opacity: 0;
  }

  &.white {
    .scroll-magic-logo {
      opacity: 1;
    }

    .scroll-magic-logo-light {
      opacity: 0;
    }
    .scroll-magic-white-bar {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    button {
      background-color: ${theme.colors.green['500']};

      &:hover {
        background-color: ${theme.colors.green['700']};
        color: ${theme.colors.white};
      }
    }
  }
`;
