import { Heading, HeadingProps } from '@chakra-ui/core';
import React from 'react';

type Props = Omit<HeadingProps, 'css'> & {
  children: React.ReactNode;
};

export const SectionHeading: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <Heading as="h3" fontSize="1.2rem" fontWeight="medium" {...rest}>
      {children}
    </Heading>
  );
};
