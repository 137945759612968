import { Box, Flex, Link, useToast } from '@chakra-ui/core';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { SDHLogoIcon, SRSLogoIcon, SRSLogoLightIcon } from 'assets/icons';
import * as C from 'components/dataDisplay';

import rootStore from '../../stores/rootStore';
import { HeaderWrapper } from './styled';

const Header = () => {
  const history = useHistory();
  const { specificationStore } = useContext(rootStore);
  const toast = useToast();

  if (history.location.pathname.includes('specification')) {
    return (
      <Flex
        px="2rem"
        h="70px"
        borderTop="1px"
        borderBottom="1px"
        borderColor="gray.a100"
        align="center"
        justify="space-between"
      >
        <Link href="/" aria-label="SRS homepage">
          <Box as={SRSLogoIcon} size={150} />
        </Link>
        <Box as={SDHLogoIcon} />
      </Flex>
    );
  }

  const generateSpecification = async () => {
    await specificationStore.createSpecification();

    if (specificationStore.isError) {
      toast({
        title: 'An error occurred.',
        description: 'There is a problem with creating the specification',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
    } else {
      history.push(`/specification/${specificationStore.specificationId}`);
    }
  };

  return (
    <HeaderWrapper
      className="scroll-magic-header"
      h={[70, 70, '6.5rem']}
      pos="fixed"
      w="100%"
      zIndex={20}
      backgroundColor={['green.500', 'green.500', 'transparent']}
    >
      <Box
        w="100%"
        h="100%"
        className="scroll-magic-white-bar"
        background="white"
        pos="absolute"
        transform="translate3d(0, -100%, 0)"
        transition="transform 0.3s, opacity 0.3s"
        opacity={0}
        borderBottom="1px solid #ececec"
      ></Box>
      <Flex
        align="center"
        direction="row"
        h="100%"
        justify={['center', 'center', 'space-between']}
        px={['1rem', '3rem', '5rem']}
      >
        <Link href="/" aria-label="SRS homepage">
          <Box pos="relative" h={34} w={[180, 180, 212]}>
            <Box
              pos="absolute"
              top="0"
              left="0"
              transition="opacity 0.3s"
              as={SRSLogoLightIcon}
              className="scroll-magic-logo-light"
              w="100%"
            />
            <Box
              w="100%"
              pos="absolute"
              top="0"
              left="0"
              transition="opacity 0.3s"
              as={SRSLogoIcon}
              className="scroll-magic-logo"
            />
          </Box>
        </Link>

        <Box display={['none', 'none', 'block']}>
          <C.LandingPageButton
            id="header-try-button"
            isDisabled={specificationStore.isLoading}
            mb="0rem"
            onClick={generateSpecification}
            h="3rem"
            px="2rem"
          >
            Try for free
          </C.LandingPageButton>
        </Box>
      </Flex>
    </HeaderWrapper>
  );
};

export default Header;
