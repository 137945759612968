import { Box } from '@chakra-ui/core';
import React, { useEffect, useState } from 'react';

import Bar from './Bar';
import { ChartLabel, ChartPercentage } from './styled';

interface ChartItemProps {
  title: string;
  percentage: number;
  dark: boolean;
  animStart: boolean;
}

const ChartItem: React.FC<ChartItemProps> = ({
  title,
  percentage,
  dark,
  animStart,
}) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [animProgress, setAnimProgress] = useState(0);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;

    if (animStart) {
      setIsAnimating(true);
      interval = setInterval(() => {
        setAnimProgress((animProgress) => {
          if (animProgress >= percentage) {
            clearInterval(interval);
            return animProgress;
          }
          return animProgress + percentage / 20;
        });
      }, 33);
    }

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [animStart]);

  return (
    <>
      <ChartLabel fontSize={['xs', 'sm', 'md']} maxW={['25%', 'none']}>
        {title}
      </ChartLabel>
      <Box w={400} maxW="50%" d="inline-block">
        <Bar length={isAnimating ? percentage : 0} dark={dark}></Bar>
      </Box>
      <ChartPercentage>{Math.floor(animProgress)}%</ChartPercentage>
    </>
  );
};

export default ChartItem;
