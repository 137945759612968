import { Heading, Text } from '@chakra-ui/core';
import styled from '@emotion/styled/macro';

import { theme } from 'styles/theme';

export const CardTitle = styled(Heading)`
  font-weight: 600;
  color: ${theme.colors.customBlack['100']};
  padding-top: 1rem;
  padding-bottom: 2rem;
`;

export const CardText = styled(Text)`
  font-size: ${theme.fontSizes.md2};
  color: ${theme.colors.customBlack['100']};
  opacity: 0.6;
`;
