import { Box, BoxProps, Image } from '@chakra-ui/core';
import React from 'react';

import LoadOnScroll from '../LoadOnScroll';
import { LoadWrapper } from '../shared/styled';

interface LazyImageProps extends BoxProps {
  src: string;
  alt: string;
  transitionDelay?: string;
}

const LazyImage: React.FC<LazyImageProps> = ({
  src,
  alt,
  transitionDelay,
  ...rest
}) => {
  return (
    <Box pos="absolute" {...rest}>
      <LoadOnScroll>
        <LoadWrapper style={{ transitionDelay: transitionDelay || '' }}>
          <Image d="block" w="100%" src={src} alt={alt} />
        </LoadWrapper>
      </LoadOnScroll>
    </Box>
  );
};

export default LazyImage;
