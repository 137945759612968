import { BoxProps, Image } from '@chakra-ui/core';
import React from 'react';

import LoadOnScroll from 'pages/LandingPage/LoadOnScroll';
import { LoadWrapper } from 'pages/LandingPage/shared/styled';

import { CardText, CardTitle } from './styled';

interface Props extends BoxProps {
  title: string;
  text: string;
  iconSrc: string;
  iconAlt: string;
  transitionDelay: string;
}

const Card: React.FC<Props> = ({
  title,
  text,
  iconSrc,
  iconAlt,
  transitionDelay,
  ...rest
}) => (
  <LoadOnScroll>
    <LoadWrapper
      textAlign={['center', 'center', 'center', 'left']}
      w={['100%', '100%', '100%', '33%']}
      px={[0, 0, 0, 26]}
      style={{ transitionDelay }}
      {...rest}
    >
      <Image
        src={iconSrc}
        alt={iconAlt}
        m={['0 auto', '0 auto', '0 auto', 0]}
        w="70px"
      />
      <CardTitle
        fontSize="xl2"
        as="h3"
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <CardText>{text}</CardText>
    </LoadWrapper>
  </LoadOnScroll>
);

export default Card;
