import { Box, Image } from '@chakra-ui/core';
import styled from '@emotion/styled/macro';

export const TanBlob = styled(Image)`
  position: absolute;
  top: 0;
  right: 0;
  width: 80%;
`;

export const AppScreen = styled(Box)`
  position: absolute;
  top: 6vw;
  right: 10vw;
  width: 90%;
`;
