import { Box, Flex } from '@chakra-ui/core';
import React from 'react';

import LoadOnScroll from '../LoadOnScroll';
import {
  BasicText,
  BasicTitle,
  Container,
  LoadWrapper,
} from '../shared/styled';
import Chart from './Chart';

const DidYouKnow = () => (
  <Box mt="150px" mb="10.7rem">
    <Container>
      <Flex
        direction={['column', 'column', 'column', 'row']}
        justify={['flex-start', 'flex-start', 'flex-start', 'space-between']}
      >
        <LoadOnScroll>
          <LoadWrapper
            textAlign={['center', 'center', 'center', 'unset']}
            w={['100%', '100%', '100%', 'auto']}
            pr={[0, 0, 0, '40px']}
          >
            <BasicTitle mb="2rem" fontSize={['2rem', '2.8rem', '4rem']}>
              Did you know?
            </BasicTitle>
            <BasicText
              mb={['2rem', '2rem', '2rem', 0]}
              maxW={['auto', 'auto', 'auto', '26.5rem']}
              lineHeight="32px"
            >
              A third of the top 20 startups failure reasons can be avoided or
              minimised thanks to a Software Requirements Specification?
            </BasicText>
          </LoadWrapper>
        </LoadOnScroll>
        <Chart />
      </Flex>
    </Container>
  </Box>
);

export default DidYouKnow;
