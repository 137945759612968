import { Button, ButtonProps } from '@chakra-ui/core';
import React from 'react';

export const ModalButton: React.FC<ButtonProps> = (props) => (
  <Button
    h="4rem"
    bg="green.400"
    color="white"
    type="submit"
    _hover={{ bg: 'green.300' }}
    _active={{ bg: 'green.400' }}
    {...props}
  >
    {props.children}
  </Button>
);
