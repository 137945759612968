import {
  Box,
  Checkbox,
  Divider,
  Flex,
  Heading,
  IconButton,
  Input,
  Link,
  Text,
  useToast,
} from '@chakra-ui/core';
import axios from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useRef } from 'react';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import * as Yup from 'yup';

import { SDHLogoIconNoDecor, SRSLogoIcon } from 'assets/icons';
import LoadOnScroll from 'pages/LandingPage/LoadOnScroll';
import { Container, LoadWrapper } from 'pages/LandingPage/shared/styled';
import { ErrorText } from 'pages/specification/steps/modal/DataForm';
import { Endpoints } from 'services/model';

import { CheckboxWrapper, VerticalDivider } from './styled';

const captchaSiteKey = process.env.REACT_APP_HCAPTCHA_SITE_KEY;
if (!captchaSiteKey) {
  throw new Error('REACT_APP_HCAPTCHA_SITE_KEY is not set');
}

type InitialValuesType = {
  email: string;
  agreement: boolean;
  hcaptcha: string;
};

const NewsletterSchema = Yup.object().shape({
  email: Yup.string().email('Must be a valid email').required('Required'),
  agreement: Yup.bool().oneOf(
    [true],
    'You must agree to our terms and conditions',
  ),
  hcaptcha: Yup.string().required('Required'),
});

const initialValues: InitialValuesType = {
  email: '',
  agreement: false,
  hcaptcha: '',
};

const Footer = () => {
  const captchaRef = useRef<HCaptcha>(null);
  const toast = useToast();

  const onNewsletterSubmit = async (
    values: InitialValuesType,
    actions: FormikHelpers<InitialValuesType>,
  ) => {
    try {
      await axios.post(
        process.env.REACT_APP_NEWSLETTER_ENDPOINT ||
          Endpoints.NEWSLETTER_SUBSCRIPTION,
        {
          email: values.email,
          source: 'SRS',
          'h-captcha-response': values.hcaptcha,
        },
      );
      toast({
        title: 'Success!',
        description: 'Thank you for subscribing!',
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
      actions.resetForm();
      captchaRef.current?.resetCaptcha();
    } catch (error) {
      console.error(error);
      toast({
        title: 'An error occurred.',
        description: 'There was a problem subscribing to the newsletter',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };
  return (
    <Box as="footer" pb={['2rem', '2rem', '6.5rem']}>
      <Container>
        <LoadOnScroll>
          <LoadWrapper
            bg="white"
            boxShadow="0 69px 144px -40px rgba(0, 100, 89, 0.11)"
            px="2rem"
            pt="24px"
            pb={['80px', '60px', '32px']}
            pos="absolute"
            top={-116}
            left={0}
            w={['100%', '100%', 'calc(100% - 10rem)']}
            mx={['0', '0', '5rem']}
          >
            <Box w={460} maxW="100%" m="0 auto">
              <Formik
                onSubmit={onNewsletterSubmit}
                validationSchema={NewsletterSchema}
                initialValues={initialValues}
              >
                {({ values, handleChange, setFieldValue, errors, touched }) => (
                  <Form>
                    <Heading
                      as="h2"
                      color="gray.a600"
                      fontSize="xxl2"
                      lineHeight={1.4}
                      fontWeight={600}
                      textAlign="left"
                    >
                      Join our newsletter
                    </Heading>
                    <Text
                      textAlign="left"
                      pb="20px"
                      color="gray.a1100"
                      fontSize="md2"
                    >
                      Get the latest information about our products
                    </Text>
                    <Box w="100%" mr="8px" h="50px" mb="20px" pos="relative">
                      <Input
                        h="50px"
                        pl="34px"
                        pr="54px"
                        py="16px"
                        rounded="full"
                        backgroundColor="gray.a40"
                        border="none"
                        color="gray.a900"
                        name="email"
                        aria-label="Email input"
                        onChange={handleChange}
                        value={values.email}
                        placeholder="Your email"
                        isInvalid={errors.email ? touched.email : false}
                        _placeholder={{
                          color:
                            errors.email && touched.email
                              ? 'red.500'
                              : 'gray.a1100',
                        }}
                      />
                      <IconButton
                        id="newsletter-submit-button"
                        aria-label="Send"
                        rounded="full"
                        w="50px"
                        h="50px"
                        bg="green.500"
                        color="white"
                        type="submit"
                        icon="arrow-forward"
                        size="lg"
                        _hover={{ bg: 'green.700' }}
                        _active={{ bg: 'green.400' }}
                        pos="absolute"
                        right={0}
                        top={0}
                        zIndex={3}
                        boxShadow="0 0 10px #fff"
                      />
                      <Box pos="absolute" bottom="-1.5rem" left="34px">
                        <ErrorText
                          error={errors.email}
                          touched={touched.email}
                        />
                      </Box>
                    </Box>

                    <CheckboxWrapper>
                      <Checkbox
                        size="sm"
                        variantColor="green"
                        textAlign="left"
                        isChecked={values.agreement}
                        onChange={() =>
                          setFieldValue('agreement', !values.agreement)
                        }
                        alignItems="flex-start"
                      >
                        <Text
                          pr={[0, 0, '50px']}
                          fontSize="14px"
                          color="gray.a1100"
                        >
                          I agree to receive the newsletter and commercial
                          information from Startup Development House{' '}
                          <Link
                            href="https://start-up.house/en/privacy-policy?utm_source=srs_generator"
                            target="_blank"
                            rel="noopener noreferrer"
                            color="gray.a1100"
                            textDecor="underline"
                          >
                            Read more
                          </Link>
                        </Text>
                      </Checkbox>
                      <Box pl="34px">
                        <ErrorText
                          error={errors.agreement}
                          touched={touched.agreement}
                        />
                      </Box>
                    </CheckboxWrapper>
                    <HCaptcha
                      id="captcha-footer"
                      sitekey={captchaSiteKey}
                      onVerify={(token) => {
                        setFieldValue('hcaptcha', token);
                      }}
                      ref={captchaRef}
                    />
                    <ErrorText
                      error={errors.hcaptcha}
                      touched={touched.hcaptcha}
                    />
                  </Form>
                )}
              </Formik>
            </Box>
          </LoadWrapper>
        </LoadOnScroll>

        <LoadOnScroll>
          <LoadWrapper>
            <Flex justify="center" align="center" mb="66px" pt="420px">
              <Box w={154} mr="48px">
                <Box as={SRSLogoIcon} w="100%" />
              </Box>
              <VerticalDivider />
              <Link
                id="sdh-link"
                href="https://start-up.house?utm_source=srs_generator"
                aria-label="Startup Development House homepage"
                target="_blank"
                rel="noopener noreferrer"
                textDecor="none"
                _hover={{ textDecor: 'none' }}
                ml="48px"
                w={154}
              >
                <Box as={SDHLogoIconNoDecor} w="100%" />
              </Link>
            </Flex>
            <Divider />
            <Flex
              mt={['2rem', '2rem', '40px']}
              justify="space-between"
              align="center"
              direction={['column', 'column', 'row']}
            >
              <Text my={['2rem', '2rem', 0]} color="gray.a400">
                Copyright © 2020 SRS generator. All rights reserved.
              </Text>
              <Box>
                <Link
                  href="https://start-up.house/en/privacy-policy?utm_source=srs_generator"
                  target="_blank"
                  rel="noopener noreferrer"
                  color="gray.a400"
                  fontWeight={600}
                >
                  Privacy & Cookie Policy
                </Link>
              </Box>
            </Flex>
          </LoadWrapper>
        </LoadOnScroll>
      </Container>
    </Box>
  );
};

export default Footer;
