import { Box, Heading, Text } from '@chakra-ui/core';
import styled from '@emotion/styled/macro';

import { theme } from 'styles/theme';

const {
  colors: { white },
} = theme;

export const TitleWrapper = styled(Box)`
  position: relative;
  z-index: 2;

  @media (max-width: 400px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const Title = styled(Heading)`
  max-width: 60rem;
  margin: 0 auto;
  padding-top: 180px;
  padding-bottom: 2rem;
  color: ${white};

  @media (min-width: 1366px) {
    font-size: 4.5rem !important;
  }
`;

export const SubTitle = styled(Text)`
  font-size: 1rem;
  color: ${white};
  margin-bottom: 2rem;

  @media (min-width: 1366px) {
    font-size: 1.8rem;
  }
`;
