import { theme as chakraTheme } from '@chakra-ui/core';

export const theme = {
  ...chakraTheme,
  colors: {
    ...chakraTheme.colors,
    customBlack: {
      100: '#020017',
    },
    green: {
      ...chakraTheme.colors.green,
      100: '#d5f1d9',
      200: '#0bbe35',
      300: '#6ec67f',
      400: '#0cbe35',
      500: '#00c285',
      600: '#006459',
      700: '#00A773',
    },
    gray: {
      ...chakraTheme.colors.gray,
      a25: '#f8f8f8',
      a40: '#f9f9f9',
      a50: '#ecf0f1',
      a100: '#e3e8f2',
      a200: '#d3dae8',
      a300: '#9ba7b3',
      a400: '#919da9',
      a500: '#3d4260',
      a600: '#2f324a',
      a700: '#f8f9fa',
      a800: '#6fc5ba',
      a900: '#363954',
      a1000: '#ebedf0',
      a1100: '#596b70',
    },
    dotColor: {
      mistyRose: '#f5d1f0',
      lightGoldenrodYellow: '#f4f2d1',
      lightBlue: '#d1e9f4',
      lightPink: '#f6efee',
      paleTurquoise: '#c1f1f4',
      lightGreen: '#c5f4c1',
      peach: '#ffd88e',
      lavender: '#cbccf2',
    },
    other: {
      royalBlue: '#6992f3',
      positiveTag: '#58d274',
      negativeTag: '#f7497b',
      red: '#ff001a',
    },
    pdf: {
      userPersonasBg: '#cfd5d9',
      userStoriesBg: '#e0e4e0',
      timelineBg: '#f1f1f3',
    },
  },
  fonts: {
    ...chakraTheme.fonts,
    body: '"TTCommons", sans-serif',
    heading: '"TTCommons", sans-serif',
  },
  radii: {
    ...chakraTheme.radii,
    none: '0',
    small: '4px',
    medium: '12px',
    big: '16px',
    full: '100rem',
  },
  fontSizes: {
    ...chakraTheme.fontSizes,
    xs: '11px',
    sm: '13px',
    md: '16px',
    md2: '20px',
    lg: '25px',
    xl: '26px',
    xl2: '30px',
    xl3: '28px',
    xxl: '36px',
    xxl2: '38px',
    xxxl: '42px',
    xxxl2: '45px',
    freakingHuge: '64px',
  },
  fontWeights: {
    ...chakraTheme.fontWeights,
    light: 300,
    normal: 400,
    bold: 600,
  },
  shadows: {
    ...chakraTheme.shadows,
    strong: '0 9px 20px 0 rgba(0, 0, 0, 0.06)',
    light: '0 3px 5px 0 rgba(11, 9, 41, 0.05)',
    lightSliderShadow: '1px 1px 5px 0px rgba(0,0,0,0.1)',
    inline: 'inset 0 0 0 3px rgba(66, 153, 225, .6)',
    shadow150: '0 2px 4px 0 rgba(16, 15, 35, 0.1)',
  },
  typography: {
    font240: {
      fontSize: '13px',
      fontWeight: 400,
    },
    font260: {
      fontSize: '13px',
      fontWeight: 600,
    },
    font360: {
      fontSize: '13px',
      fontWeight: 600,
    },
  },
  borderRadius: {
    small: '4px',
    big: '12px',
  },
  animation: {
    fast: '160ms cubic-bezier(0.2, 0.8, 0.4, 1)',
    slow: '460ms cubic-bezier(0.2, 0.8, 0.4, 1)',
  },
};

export const breakpoints = ['30em', '48em', '62em', '80em', '126em'];
