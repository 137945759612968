import { Flex } from '@chakra-ui/core';
import React, { useState } from 'react';

type Props = {
  handleRemove: () => void;
};

export const RemoveButton: React.FC<Props> = ({ handleRemove }) => {
  const [isFocused, setFocused] = useState(false);
  return (
    <Flex
      role="button"
      tabIndex={0}
      onClick={handleRemove}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          handleRemove();
        }
      }}
      aria-label="Remove item"
      w="2rem"
      h="2rem"
      align="center"
      justify="center"
      pos="absolute"
      top="50%"
      right="0"
      cursor="pointer"
      outline="none"
      fontWeight="bold"
      background="white"
      rounded="full"
      fontSize="0.85rem"
      color="gray.a400"
      transform="translateY(-50%) translateX(50%)"
      transition="0.15s all"
      boxShadow={isFocused ? 'outline' : 'md'}
      zIndex={1}
    >
      X
    </Flex>
  );
};
