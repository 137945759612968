import { Box as ChakraBox } from '@chakra-ui/core';
import { keyframes } from '@emotion/core';
import styled from '@emotion/styled/macro';

const waveFrontAnimation = keyframes`
  100% {
    transform: translate(-50%, 0);
  }
`;

const waveBackAnimation = keyframes`
  100% {
    transform: translate(50%, 0);
  }
`;

export const Box = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  overflow: hidden;
`;

export const Water = styled(ChakraBox)`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  transition: all 0.3s;
`;

export const WaterWaveBack = styled.svg`
  width: 500%;
  position: absolute;
  bottom: 100%;
  right: 0;
  animation: ${waveBackAnimation} 2.8s infinite linear;
`;

export const WaterWaveFront = styled.svg`
  width: 500%;
  position: absolute;
  bottom: 100%;
  left: 0;
  margin-bottom: -1px;
  animation: ${waveFrontAnimation} 1.4s infinite linear;
`;
