import { AxiosResponse } from 'axios';
import { action, observable } from 'mobx';

import { api } from 'services/api';
import { CreateTypes, Endpoints, Specification } from 'services/model';

import { RootStore } from './rootStore';

export class ApiStore {
  rootStore: RootStore;

  @observable values: Specification = {
    pageDescriptions: [],
    requirements: [],
    userStories: [],
    personas: [],
    futureIterations: [],
    sitemaps: [],
    wireframes: [],
    goalsAndProjectDescription: {
      goal: '',
      problems: '',
      vision: '',
    },
    timelineBudgetsRisks: {
      timeline: '',
      budgets: '',
      risks: '',
    },
  };

  @observable isError = false;
  @observable isLoading = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action
  async getData(endpoint: Endpoints): Promise<void> {
    this.setError(false);
    try {
      this.setLoading(true);
      const response = await api.get(
        this.rootStore.specificationStore.specificationId,
        endpoint,
      );
      this.setValues(response?.data);
    } catch {
      this.setError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @action
  async saveData(endpoint: Endpoints, data: CreateTypes): Promise<void> {
    this.setError(false);
    try {
      this.setLoading(true);
      await api.add(
        this.rootStore.specificationStore.specificationId,
        endpoint,
        data,
      );
    } catch {
      this.setError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @action
  async saveFile(
    endpoint: Endpoints,
    formData: FormData,
  ): Promise<AxiosResponse | undefined> {
    this.setError(false);
    try {
      this.setLoading(true);
      return await api.addFile(
        this.rootStore.specificationStore.specificationId,
        endpoint,
        formData,
      );
    } catch (error) {
      this.setError(true);
      return error.response;
    } finally {
      this.setLoading(false);
    }
  }

  @action
  async editData(
    endpoint: Endpoints,
    id: string | null,
    data: CreateTypes,
  ): Promise<void> {
    this.setError(false);
    try {
      this.setLoading(true);
      await api.edit(
        this.rootStore.specificationStore.specificationId,
        id,
        endpoint,
        data,
      );
    } catch {
      this.setError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @action
  async deleteData(endpoint: Endpoints, id: string): Promise<void> {
    this.setError(false);
    try {
      this.setLoading(true);
      await api.delete(
        this.rootStore.specificationStore.specificationId,
        endpoint,
        id,
      );
    } catch {
      this.setError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @action setLoading = (status: boolean) => {
    this.isLoading = status;
  };

  @action setError = (status: boolean) => {
    this.isError = status;
  };

  @action setValues = (newValues: Partial<Specification>) => {
    this.values = { ...this.values, ...newValues };
  };
}
