import { Button as ChakraButton, ButtonProps } from '@chakra-ui/core';
import React from 'react';

interface LandingPageButtonProps extends ButtonProps {
  light?: boolean;
}

export const Button: React.FC<ButtonProps> = ({ children, ...rest }) => {
  return (
    <ChakraButton
      bg="green.200"
      color="white"
      h="3rem"
      px="2rem"
      rounded="full"
      _active={{ bg: 'green.500' }}
      _hover={{ bg: 'green.300' }}
      _disabled={{ bg: 'gray.300' }}
      {...rest}
    >
      {children}
    </ChakraButton>
  );
};
export const LandingPageButton: React.FC<LandingPageButtonProps> = ({
  children,
  light,
  ...rest
}) => {
  return (
    <ChakraButton
      bg={light ? 'green.500' : 'green.600'}
      color="white"
      h="4.6rem"
      px="3.5rem"
      rounded="full"
      _active={{ bg: 'green.500' }}
      _hover={{
        bg: light ? 'green.700' : 'white',
        color: light ? 'white' : 'black',
      }}
      _disabled={{ bg: 'gray.600' }}
      fontSize="md"
      {...rest}
    >
      {children}
    </ChakraButton>
  );
};

export const PersonaButton: React.FC<ButtonProps> = ({ children, ...rest }) => {
  return (
    <ChakraButton
      bg="green.300"
      color="white"
      h="3.4rem"
      w="100%"
      px="3.5rem"
      rounded="medium"
      _active={{ bg: 'green.500' }}
      _hover={{ bg: 'green.300' }}
      _disabled={{ bg: 'gray.300' }}
      fontSize="md"
      {...rest}
    >
      {children}
    </ChakraButton>
  );
};

export const ButtonOutlined: React.FC<ButtonProps> = ({
  children,
  ...rest
}) => {
  return (
    <ChakraButton
      bg="white"
      border="1px solid black"
      color="black"
      h="3rem"
      px="2rem"
      rounded="full"
      _active={{ bg: 'gray.200' }}
      _hover={{ bg: 'gray.100' }}
      _disabled={{ borderColor: 'gray.200', color: 'gray.400' }}
      {...rest}
    >
      {children}
    </ChakraButton>
  );
};

export const ButtonBlack: React.FC<ButtonProps> = ({ children, ...rest }) => {
  return (
    <ChakraButton
      bg="black"
      color="white"
      h="3rem"
      px="2rem"
      rounded="medium"
      _active={{ bg: 'gray.700' }}
      _hover={{ bg: 'gray.800' }}
      _disabled={{ bg: 'gray.200' }}
      {...rest}
    >
      {children}
    </ChakraButton>
  );
};

export const AddNewButton: React.FC<Omit<ButtonProps, 'children'>> = ({
  children = 'Add new',
  ...rest
}) => (
  <ChakraButton
    bg="gray.a700"
    color="gray.a400"
    border="1px dashed"
    borderColor="gray.a200"
    w="100%"
    h="4rem"
    px="2rem"
    rounded="medium"
    _active={{ bg: 'gray.300' }}
    _hover={{ bg: 'gray.a100', border: '1px solid', color: 'gray.a500' }}
    _focus={{
      bg: 'gray.a100',
      border: '1px solid',
      color: 'gray.a500',
      boxShadow: 'outline',
    }}
    {...rest}
  >
    + &nbsp;
    {children}
  </ChakraButton>
);
